.surcharge-info {
  display: flex;
  margin: 8px 12px 0px 14px;
  background-color: #e5e7eb;
  padding: 14px 16px;
  font-size: 12px;
  border-radius: 6px;
  justify-content: space-between;
  .localized-number {
    font-size: 12px;
  }
}

.payment-surcharge-fees {
  @media (max-width: 768px) {
    h4 {
      font-size: 12px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      color: #333;
      padding: 30px 0 5px 0;
    }
  }
}
