.RootMenu {
  padding: 0px;
  border: none;
  font-size: 16px;
  background: none;
  border-radius: 0;
  border: 0;
  text-align: right;
  margin-right: -8px;
}

.RootMenu[data-open],
.RootMenu:hover {
  cursor: pointer;
  opacity: 0.8;
}

.Menu {
  background-color: #fff;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1);
  outline: 0;
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 6px;
  z-index: 9999;
}

.MenuItem {
  background: none;
  width: 100%;
  border: none;
  font-size: 16px;
  text-align: left;
  line-height: 1.8;
  min-width: 110px;
  margin: 0;
  outline: 0;
}

.MenuItem.btn {
  background-color: #fff;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  color: rgb(55, 65, 81);
}

.MenuItem.btn:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.MenuItem.btn:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.MenuItem.btn.separator-top {
  border-top: 1px solid #e1e4e8 !important;
}

.MenuItem.btn.separator-bottom {
  border-bottom: 1px solid #e1e4e8;
}

.MenuItem.btn.delete-button,
.MenuItem.btn.delete-button:hover {
  color: #a94442 !important;
}

.app-theme .MenuItem.btn:hover,
.app-theme .MenuItem.btn.hover,
.app-theme .MenuItem.btn:focus,
.app-theme .MenuItem.btn.focus {
  background-color: rgb(241 245 249);
  border: 0;
  box-shadow: none;
  cursor: pointer;
  color: rgb(55, 65, 81);
}

.app-theme .MenuItem.btn:hover:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.app-theme .MenuItem.btn:hover:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.MenuItem[data-open],
.MenuItem:focus,
.MenuItem:not([disabled]):active {
  background: #d7dce5;
}
