.color-select {
  padding: 0 0 10px 0;
  margin: 0 -4px;
}
.color-select.disabled {
  opacity: 0.5;
}
.color-select:after {
  content: '';
  display: table;
  clear: both;
}
.color-select-option {
  position: relative;
  float: left;
  margin: 3px;
  padding: 6px 6px;
  color: transparent;
  transform: scale(1);
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
  font-size: 1.1rem;
  width: 30px;
  height: 30px;
  border: none;
  transition: background-color 200ms ease-out 0ms, opacity 80ms ease-out 0ms,
    transform 80ms ease-out 0ms, box-shadow 80ms ease-out 0ms;
}
.color-select-option.selected {
  color: #fff;
}
.color-select-option.selected i {
  display: inline-block;
  font-size: 15px;
}
.color-select.loaded .color-select-option:hover {
  color: #fff;
  transform: scale(1.05);
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.18);
}

/* 
  react-color Chrome input values overflow when the component shrinks.
  This is an attempt at fixing this UI bug.
  When you toggle the next state of inputs, they increment by 1,
  so unless the user toggles this multiple times they won't see this visual bug.
*/
#rc-editable-input-10,
#rc-editable-input-9,
#rc-editable-input-8,
#rc-editable-input-7,
#rc-editable-input-6,
#rc-editable-input-5,
#rc-editable-input-4,
#rc-editable-input-3,
#rc-editable-input-2,
#rc-editable-input-1 {
  padding: 0;
}
