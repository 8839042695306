.subscription ul {
  list-style-type: none;
  padding: 10px 0 0;
  border-top: 1px solid #ececec;
}

.subscription li {
  padding: 5px;
  color: #666;
  font-size: 16px;
}

.subscription li .subscription-feature {
  padding-left: 10px;
}

.subscription .focus-body li {
  text-align: center;
  display: block;
}

.subscription .focus-body .currency {
  color: #555;
}

.subscription .focus-body .currency-symbol,
.subscription .focus-body .currency-amount {
  font-weight: 500;
}

.subscription .focus-body .currency-code,
.subscription .focus-body .currency-suffix {
  font-size: 12px;
}

.subscription .focus-body .currency-suffix {
  display: block;
}

.subscription .focus-body table {
  font-size: 20px;
  width: 100%;
  margin: 0 0 15px;
}
.subscription .focus-body table td {
  text-align: center;
  color: #555;
}

.subscription .focus-body table td i {
  padding: 0 20px;
}

.subscription table .subscription-upgrade-name {
  font-size: 12px;
}

/* SubscriptionTiered */
.interval-select-container {
  width: 45%;
}

@media only screen and (max-width: 767px) {
  .interval-select-container {
    width: 100%;
  }
}

.tiered-sub-button {
  text-align: center;
}

.tiered-sub-button button {
  font-size: 2em !important;
  padding: 10px 15px !important;
  width: 100%;
  margin: 0 auto !important;
  text-align: center !important;
  display: block !important;
}

.text-from-price {
  font-size: 20px !important;
  text-decoration-thickness: 1px !important;
}

.strikethrough-price {
  font-size: 16px !important;
  text-decoration-thickness: 1px !important;
}

.tiered-sub-subtitle {
  font-size: 0.85em !important;
}
