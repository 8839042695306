@import '../../styles/variables';

.currency-select .Select-arrow-zone {
  display: none !important;
}

.currency-select .currency-select-flag {
  width: 40px;
  height: 24px;
  margin: 5px 0;
  border-radius: 4px;
  display: block;
  object-fit: cover;
}
.currency-select .currency-select-symbol {
  padding-right: 10px;
  font-size: 18px;
  font-weight: 500;
}
.currency-select.is-disabled .invoice-currency-option {
  opacity: 0.5;
}

.currency-select-code {
  display: inline-block;
  font-weight: normal;
}
.currency-select-name {
  display: block;
  font-size: 12px;
}
.currency-select-option img {
  width: 40px;
}
.currency-select {
  .Select-value {
    padding: 0 $input-padding-horizontal !important;
  }
}
.invoice-currency-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    cursor: pointer;
  }

  .currency-flag {
    display: flex;
    align-items: center;
  }
}
