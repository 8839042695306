.item-row {
  transition: transform 0.5s ease-out;
  cursor: pointer;

  .item-row-action {
    vertical-align: bottom;
    display: table-cell;
  }
}

.item-row .item-row-rate {
  text-align: right;
}
.item-row-name {
  display: block;
}
.item-row-total {
  display: block;
  font-weight: 500;
}

.item-row-quantity,
.item-row-rate,
.item-row-amount {
  text-align: right;
  min-width: 120px;
  .edit {
    line-height: 32px;
  }
}

/* delete */
.item-row.item-row-delete {
  background-color: #fafafa;
}
.item-row.item-row-delete .item-row-name,
.item-row.item-row-delete .item-row-description,
.item-row.item-row-delete .item-row-quantity,
.item-row.item-row-delete .item-row-rate,
.item-row.item-row-delete .item-row-tax,
.item-row.item-row-delete .item-row-amount {
  opacity: 0.7;
}
.item-row.item-row-delete .item-row-amount .currency-amount {
  text-decoration: line-through;
}
.item-row-description textarea,
.item-row-name input {
  width: 100%;
  margin-bottom: 5px;
}

.item-row .item-row-actions .btn {
  min-width: 30px;
  padding: 4px;
}
.item-row .item-row-actions .btn i {
  padding: 0;
}
.item-row .item-row-actions .btn-cancel {
  margin-bottom: 0.4rem;
}

td.item-row-tax {
  width: 90px;
}
.item-row-rate input,
.item-row-quantity input,
.item-row-discount input {
  text-align: right;
}
.item-row input {
  border-top: 1px solid #ccc;
  border-radius: 3px;
}
.item-row .item-row-discount > span {
  display: block;
}
.item-row .item-row-discount input,
.item-row .item-row-tax input {
  border: 1px solid #ccc;
  border-radius: 3px !important;
  padding: 6px 8px;
  text-align: right;
  margin: 0 5px;
  max-width: 60px;
}

.item-row .item-row-discount input {
  max-width: 40px;
  margin: 0;
}
.item-row .item-row-discount {
  text-align: right;
}
.item-tax-rate-input,
.item-discount-rate-input {
  font-size: 15px !important;
}
.item-description-input {
  border: 1px solid #ccc;
  border-radius: 3px;
  resize: none;
}
.item-taxable-input {
  margin: 0 5px;
}
.invoice-items tr {
  border-bottom: 1px dashed #ddd;
}
.invoice-items tr.item-row-n {
  border: none;
}

.invoice-items tr td.item-row-tax {
  text-align: center;
  min-width: 40px;
  button {
    outline: none;
    padding: 2px 0;
  }
}
.react-numeric-input b {
  border: none !important;
  box-shadow: none !important;
  background: none !important;
}
.item-row-tax .react-numeric-input b,
.item-row-discount .react-numeric-input b {
  right: 5px !important;
}
.item-row-rate .react-numeric-input > input {
  transition: color 500ms ease;
  &.animate {
    color: #23ad71;
  }
}
.item-row-amount .item-row-total {
  padding: 4px 0;
}
.item-row-amount .item-row-tax .item-row-tax-inclusive {
  padding-right: 3px;
}
.item-row-amount .item-row-tax .item-row-tax-label {
  padding-left: 3px;
}

.btn-checkbox {
  font-size: 1.8em;
  border: none;
  background-color: transparent;
}
.btn-checkbox.disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

.item-row .currency.amount-tax,
.item-row .currency.amount-discount {
  font-size: 0.9rem;
  color: #999;
}

.item-row .currency.amount-tax .currency-prefix,
.item-row .currency.amount-discount .currency-prefix {
  font-size: 0.8rem;
}

@media only screen and (min-width: 768px) {
  .item-row .item-row-actions {
    width: 50px;
  }
  .item-row .item-row-discount {
    width: 60px;
  }

  .item-row .item-row-discount-suffix {
    padding-left: 3px;
  }
}

@media only screen and (max-width: 767px) {
  .item-row .item-row-discount-suffix {
    display: none;
  }

  .table .item-row .item-row-actions {
    padding-top: 16px;
    text-align: left;
  }
}

.item-row {
  .item-row-amount {
    .item-row-action {
      position: absolute;
      bottom: 8px;
      right: 0px;
      .add-input-field-button {
        margin: 8px 0 0 8px;
        min-width: 65px;
        &.inverted {
          box-shadow: none;
          background: none;
        }
      }
    }
  }
}
