@import '../../styles/mixins';

.mobileMenu {
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  padding: 16px 12px;
  background-color: #444;
  color: white;

  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  z-index: 9;
  left: 0;
  top: 0;

  transition: ease-in 200ms;
  opacity: 0;
  transform: translateY(-20px);
  pointer-events: none;

  &.visible {
    transform: translateY(50px);
    pointer-events: inherit;
    opacity: 1;
  }

  .nav-items {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &.nav-item {
    width: 100%;
  }

  .nav-access-items {
    display: flex;
    align-items: center;
    flex-direction: column;

    &.nav-item {
      width: 100%;
    }

    &.signed-in-user {
      flex-direction: row-reverse;
      padding-left: 2rem;
    }
  }

  #signup-button {
    margin-right: 10px;
  }
}

.nav-container {
  position: relative;
  z-index: 10;
  background-color: #444;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-content {
  width: 100%;
  padding: 0 15px;
  height: 100%;
  display: flex;
  min-height: 64px;

  @media (min-width: 1200px) {
    width: 1140px;
  }

  .brand {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 20px;
    &:hover,
    &:focus {
      color: white;
      text-decoration: none;
    }

    img {
      width: 32px;
      height: 32px;
      padding: 0px;
      margin-right: 8px;
    }

    .name {
      width: max-content;
      font-size: 16px;
      font-weight: 600;
      text-transform: capitalize;
      flex: unset;
    }
  }

  .nav-items {
    display: flex;
    align-items: center;
  }

  .nav-access-items {
    font-weight: 600;
    margin-left: auto;
    display: flex;
    align-items: center;

    @media screen and (max-width: 767px) {
      margin-left: 0px;
    }
  }

  .nav-toggler {
    margin-left: auto;
    cursor: pointer;
    align-self: center;
  }
}

.nav-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 12px;
  margin-left: 8px;
  border-radius: 8px;
  align-self: initial;

  &:first-child {
    margin-left: 0;
  }

  &.active,
  &:hover {
    background-color: #5e5e5e;
  }

  &.disabled {
    pointer-events: none;
  }

  & .nav-item-badge {
    color: #ff5722;
    display: inline-block;
    font-size: 11px;
    margin: -10px 0 0 3px;
  }

  &:hover,
  &:focus {
    color: white;
    text-decoration: none;
  }

  @media screen and (max-width: 1080px) {
    padding: 8px 10px;
    margin-left: 6px;
    font-size: 13px;

    .nav-item-badge {
      font-size: 9px;
    }
  }
}

// --------------- Nav Mobile Menu -----------------
@media screen and (max-width: 1023px) {
  .mobile-icon {
    width: 20px !important; // override FontAwesomeIcon default width
    height: 20px;
    color: #9ca3af;
    &.active,
    &:hover {
      color: #ffffff;
    }
  }
  .nav-item {
    width: 100%;
    height: 40px;
    margin: 0;
    font-size: 16px;
    margin-top: 4px;
    color: #d1d5db;
    &:first-child {
      margin-top: 0;
    }
    &.active,
    &:hover {
      background-color: #5e5e5e;
      color: #ffffff;
    }
  }
  .signed-in-user {
    padding-left: 0 !important;
  }
  .btn-light {
    margin-left: 1rem !important;
  }
  .nav-access-items {
    margin-left: 0 !important;
    justify-content: space-between !important;
    flex-wrap: wrap;

    .nav-item {
      margin: 0 !important;
      width: 100%;
    }

    .divider {
      display: none !important;
    }

    span {
      margin-top: 8px;
      width: 100%;

      a {
        width: 100%;
        transform: none !important;
        justify-content: center;
      }
    }
  }
  .nav-content {
    padding: 0px 24px;
  }
}

.login-link {
  @media screen and (max-width: 767px) {
    padding-right: 0;
  }
}

.divider {
  cursor: default;
  align-self: stretch;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  border-radius: 2px;
}

.wrapper {
  @media screen and (max-width: 767px) {
    padding-right: 8px;
  }
}

@media screen and (min-width: 1024px) {
/* --------------- Nav Dropdown -----------------

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 12px;
  margin-left: 8px;
  border-radius: 8px;
  align-self: initial;

  &:first-child {
    margin-left: 0;
  }

  &.active,
  &:hover {
    background-color: #5e5e5e;
  }

  &.disabled {
    pointer-events: none;
  }

  &:hover,
  &:focus {
    color: white;
    text-decoration: none;
  }
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  padding-top: 1rem;
  padding-bottom: 1rem;
  min-width: 100px;
  background-color: #fff;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); /* Equivalent to shadow-lg in Tailwind */
  border-radius: 0.75rem;
  margin-top: 25px;
  margin-left: -1rem;
  z-index: 31;
}

/* Links inside the dropdown */
.dropdown-content a {
  font-weight: 600;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  color: #2d3748; /* Equivalent to text-gray-800 in Tailwind */
}

.dropdown-content::before {
  content: '';
  position: absolute;
  top: -15px;
  width: 100%;
  height: 20px;
  background-color: transparent;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #f1f1f1}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}
}

// --------------- Nav Dropdown Mobile -----------------

@media screen and (max-width: 1023px) {
  .more-btn {
    display: none;
  }

  .dropdown {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
  }

  .dropdown-content {
    display: flex;
    flex-direction: column;
    justify-content: left;
  }

  .dropdown-content a {
    font-weight: 600;
    width: 100%;
    height: 40px;
    margin: 0;
    padding: 8px 10px;
    font-size: 16px;
    margin-top: 4px;
    border-radius: 0.75rem;
    color: #d1d5db;
    text-decoration: none;
    &.active,
    &:hover {
      background-color: #5e5e5e;
      color: #ffffff;
    }
  }
}

.badge-premium {
  padding: 6px 8px;
  border-radius: 30px;
  background-color: #5e5e5e;
  cursor: pointer;
  }