.invoice-list {
  margin-bottom: 30px;
}
.invoice-list table {
  background-color: #fff;
  overflow-x: scroll;
}
.invoice-list table td,
.invoice-list table th {
  border: none;
  border-bottom: 1px solid #ececec;
}

.invoice-list .invoice-head-invoice {
  padding-left: 21px;
}

.invoice-list table td {
  border-top: 1px solid #ececec;
  border-left-style: none;
  border-right-style: none;
}

.invoice-list table {
  td,
  th {
    vertical-align: middle;
    @media screen and (max-width: 767px) {
      padding: 0.5rem;
    }
  }
}
.invoice-list table th i {
  padding: 0 3px;
}

.invoice-list .invoice-head-state {
  width: 80px;
}

.invoice-list table tbody tr:hover {
  background-color: rgba(60, 194, 121, 0.075);
}
.invoice-list table tbody tr.invoice-row-loading:hover,
.invoice-list table tbody tr.invoice-row-total:hover {
  background-color: #fff;
}
.invoice-list table tbody tr.invoice-row-year td {
  background-color: #f8fafc;
  font-weight: 500;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 21px;
  padding-left: 21px;
}

.invoice-list .invoice-head-total {
  text-align: right;
}

.invoice-list .invoice-row-total .currency .currency-prefix {
  display: inline-block;
}
.invoice-list .invoice-row-total .currency .currency-symbol {
  margin-left: 15px;
}
.invoice-list .invoice-row-total .currency .localized-number {
  margin-left: 8px;
}
.invoice-list-body .btn-link,
.invoice-list-body .btn-link:not([href]) {
  color: #3cc279;
  text-decoration: underline;
  cursor: pointer;
}
.invoice-list-body .btn-link:hover,
.invoice-list-body .btn-link:not([href]):hover {
  color: #3cc279;
  text-decoration: underline;
}
.invoice-list-footer {
  padding: 0.8rem;
  color: #bbb;
}
.invoice-list-footer i {
  padding-right: 5px;
}

.invoice-list-header {
  row-gap: 4px;
}

.invoice-list-header .btn-group .btn {
  word-break: break-all;
}

.invoice-list-header,
.estimate-list-header {
  .btn.btn-filter.active,
  .btn.btn-filter.active:hover {
    box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.3), 0 1px 0 rgba(255, 255, 255, 0.5);
  }

  .btn.btn-filter {
    background: rgb(229, 231, 235);
  }
}

.invoice-list.loading table {
  opacity: 0.5;
  transition: opacity 0.1s ease-out;
}

.doc-list-actions {
  display: flex;

  .btn-invoice-new,
  .btn-estimate-new {
    flex-shrink: 0;
  }
}

@media (max-width: 768px) {
  .invoice-list-header.doc-search-container {
    flex-wrap: wrap;
    row-gap: 8px;

    .btn-group-filter {
      width: 100%;
    }
  }

  .doc-search-container {
    column-gap: 4px;

    .doc-list-actions {
      justify-content: space-between;
      width: 100%;
    }
  }
}

@media screen and (max-width: 374px) {
  .invoice-list-header {
    display: flex;
    flex-direction: column-reverse;
    align-items: stretch;
    .btn-invoice-new {
      margin-bottom: 4px;
    }
    .btn-group {
      display: flex;
      .btn {
        flex: 1 1 auto;
      }
    }
  }
}

.invoice-head-invoice {
  padding-left: 21px;
}
