.payments-instructions-link {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #057BFF;
}

.payments-instructions-link:hover {
  color: #057BFF;
}
