.subscription.paywall-ui-abtest {
  font-family: 'Quicksand', sans-serif;
}

.subscription.paywall-ui-abtest {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 320px) {
  .paywall-ui-abtest .focus-header {
    margin-bottom: 1.5rem;
  }
}

@media screen and (min-width: 321px) {
  .paywall-ui-abtest .focus-header {
    margin-bottom: 3rem;
  }
}

.subscription .paywall-ui-abtest li {
  color: black;
  font-weight: 500;
  font-size: 18px;
}

.subscription .paywall-ui-abtest .btn-prime {
  font-size: 16px;
  border: none;
  color: #fff;
  padding: 10px 12px;
  border-radius: 5px;
  width: 90%;
}

@media screen and (max-width: 320px) {
  .subscription .paywall-ui-abtest .btn-prime.btn-checkout-monthly {
    margin-top: 5px;
  }
}

@media screen and (min-width: 321px) {
  .subscription .paywall-ui-abtest .btn-prime.btn-checkout-monthly {
    margin-top: 15px;
  }
}

@media screen and (min-width: 575px) {
  .subscription .paywall-ui-abtest .btn-prime.btn-checkout-monthly {
    margin-top: 22px;
  }
}

.subscription .paywall-ui-abtest .btn-prime.btn-checkout-monthly {
  margin-bottom: 5px;
}

.subscription .paywall-ui-abtest .btn-prime.btn-checkout-monthly:disabled {
  background: #d6d6d6;
}

.subscription .paywall-ui-abtest .btn-prime.btn-checkout-annual {
  background: #ff5722;
  background: linear-gradient(#ff5e2d, #ff5722);
  color: #fff;
}

.subscription .paywall-ui-abtest .btn-prime.btn-checkout-annual:hover {
  background: linear-gradient(#ff764c, #ff5722);
  color: #fff;
}
