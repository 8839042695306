/* print fixes */

@media print {
  .invoice-detail,
  .invoice-detail .container,
  .invoice-detail .container .col-lg-9 {
    margin: 0 !important;
    padding: 0 !important;
  }
  .invoice-detail .container,
  .invoice-detail .container .col-lg-9 {
    max-width: 100%;
  }

  body,
  body.app-theme {
    background-color: #fff;
  }
  .app {
    padding: 0;
  }
  #app,
  .invoice-detail-body {
    min-height: 0;
    display: block;
    box-sizing: border-box;
  }
  .invoice-container {
    border: none !important;
  }
  .col-lg-9,
  .container {
    width: 100% !important;
  }
  .invoice-detail-body {
    border: none !important;
    padding: 0;
  }
  .invoice-logo,
  .invoice-title {
    padding-top: 30px;
  }
  .row {
    margin: 0;
  }
  .invoice-detail-title h1 {
    box-shadow: inherit !important;
  }
  .col-lg-6.invoice-address {
    float: left;
    width: 50%;
  }
  .footer {
    display: none;
    border: none;
  }

  /*
    HACK bootstrap responsive override
    avoid invalid collapse of xs
  */
  .col-xs-12 {
    float: left !important;
    width: 50% !important;
  }

  /* ff print fixes */
  .invoice-table {
    border-collapse: collapse !important;
    border: none !important;
  }
  .invoice-table th {
    color: rgba(255, 255, 255, 1) !important;
  }
}
