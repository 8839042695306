/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 16px;
  margin-bottom: 0.2rem;
}

/* Hide default HTML checkbox */
.switch .switch-input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  border-radius: 34px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  border-radius: 50%;
  height: 10px;
  width: 10px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}

.switch-input:checked + .slider {
  background: linear-gradient(#ff5e2d, #ff5722);
}

.switch-input:checked + .slider:before {
  transform: translateX(11px);
}

.switch-input:disabled + .slider {
  opacity: 0.5;
  cursor: not-allowed;
}
