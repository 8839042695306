@import './mixins';
@import './themeFocus';
@import './print';
@import './variables';

/* forms */
@include placeholder {
  @include smoothFont();
  color: $input-enabled-color;
}

@include placeholderDisabled {
  @include smoothFont();
  color: $input-disabled-color;
}

/* global */

html {
  font-size: 62.5%;
}

html,
body {
  font-weight: 400;
  margin: 0;
  padding: 0;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  line-height: normal;
}
body {
  -webkit-font-smoothing: antialiased;
  -webkit-print-color-adjust: exact;
  text-rendering: optimizeLegibility;
  print-color-adjust: exact;
  background-color: rgb(243, 244, 246);
  color: #454545;
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif,
    Helvetica, Arial, Apple Color Emoji, Segoe UI Emoji;
  font-size: 1.2rem;
}
header,
h1,
h2,
h3,
h4,
h5 {
  @include smoothFont();
  color: #555;
  font-weight: 500;
  margin: 0;
}
a,
a:hover {
  color: inherit;
}
p {
  margin: 0;
}

// font-weight
header,
h2,
h3,
h4,
h5,
b,
label,
table th {
  font-weight: 500;
}

h1 {
  font-weight: 700;
  font-size: 2.7rem !important;
}

/* app */
#app {
  padding-bottom: 100px;
}

#modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  .modal-wrapper {
    height: 100vh;
    width: 100vw;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding: 0 1rem;
    background: rgba(255, 255, 255, 0.8);
  }
  .modal-box {
    width: 100%;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    @media screen and (min-width: 768px) {
      width: 60vw;
    }
    @media screen and (min-width: 1440px) {
      width: 40vw;
    }
  }
}

/* btn */
.app-theme .btn {
  background: rgb(229, 231, 235);
  font-size: 14px;
  color: rgb(55, 65, 81);
  padding: 8px 16px;
  border: 1px solid rgb(209, 213, 219);
  border-radius: 6px;
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  min-width: 60px;
  cursor: pointer;
  transition: ease-in 100ms;

  &:not([disabled]):hover {
    color: rgb(107, 114, 128);
  }
}

.app-theme .form-control {
  font-size: inherit;
}

.app-theme .btn.btn-prime:disabled,
.app-theme .btn.btn-prime:disabled:hover,
.app-theme .btn:disabled,
.app-theme .btn:disabled:hover {
  opacity: 0.5;
  box-shadow: none;
  cursor: not-allowed;
}

.app-theme .btn.btn-prime.active:disabled,
.app-theme .btn.btn-prime.active:disabled:hover {
  color: #888;
  background-image: linear-gradient(#bbb, #ddd);
  box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.3), 0 0 0 rgba(255, 255, 255, 0.5);
}

/* prime */
.app-theme .btn.btn-prime {
  font-weight: 500;
  border-radius: 6px;
  background: #444444;
  border: 1px solid #e3e3e3;
  color: #f9fafb;

  .sub-label {
    font-size: 9px;
  }

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background: #525252;
    color: #f9fafb;
  }

  &:disabled {
    background-color: rgb(229, 231, 235);
    color: rgb(55, 65, 81);
    border: 1px solid rgb(209, 213, 219);
  }
}

/* dark */
.app-theme .btn.btn-dark {
  font-weight: 200;
  background: #343a40;
  border-color: #343a40;
  color: #fff;
}

/* light */
.app-theme .btn.btn-light {
  color: #555;
  background: #fff;
  min-width: fit-content;
}
.app-theme .btn.btn-light:hover {
  background: #fff;
  color: #555;
  transform: scale(1.05);
}

.cancel-subscription-theme {
  background: #fff;
}

.app-theme {
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .btn-group > .btn:not(:first-child),
  .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  @media screen and (max-width: 315px) {
    .btn-filter {
      font-size: 10px;
    }
  }

  .btn.btn-danger {
    background-color: #a94442;
    color: #fff;

    &:hover {
      background-color: #883533;
      color: #fff;
    }
  }

  .invoice-actions .btn.active,
  .invoice-actions .btn.active:hover,
  .invoice-list-header .btn.active,
  .invoice-list-header .btn.active:hover {
    box-shadow: inset 0 1px 5px rgb(0 0 0 / 20%), 0 1px 0 rgb(255 255 255 / 30%);
  }

  @media screen and (max-width: 432px) {
    .invoice-list-header {
      display: flex;
      flex-direction: column-reverse;
      align-items: stretch;
      .btn-invoice-new {
        margin-bottom: 4px;
      }
      .btn-group {
        display: flex;
        .btn {
          flex: 1 1 auto;
        }
      }
    }
    .btn-group {
      flex: auto;
    }
    .btn {
      flex: 1 1 auto;
    }
    .doc-search-container .doc-list-actions {
      align-items: baseline;
    }
    .doc-search-container {
      flex-wrap: wrap;
      row-gap: 8px;
    }
    #doc-search {
      padding: 10px;
    }
  }

  @media screen and (max-width: 374px) {
    .btn-invoice-new {
      padding: 8px 12px;
    }
    .btn-estimate-new {
      padding: 8px 12px;
    }
  }

  input,
  textarea,
  .photo-drop-zone {
    @include inputCommons();
    transition: ease-in 100ms;

    &:disabled,
    &.disabled {
      color: $input-disabled-color;
      border-color: $input-disabled-color;
    }
  }

  .Select-control {
    border: 1px solid $input-enabled-color;
  }

  .Select.is-disabled {
    .Select-control {
      border-color: $input-disabled-color;
      background-color: #fff;
      cursor: not-allowed;
    }
    .Select-value-label {
      color: $input-disabled-color !important;
    }
  }

  .Select-control {
    height: 32px;
    border-radius: 3px;
  }
  .Select-input {
    height: 32px;
    padding: 0 $input-padding-horizontal;
    input {
      border: none !important;
      box-shadow: none !important;
    }
  }
  .Select-value {
    padding: $input-padding-vertical $input-padding-horizontal !important;
    line-height: inherit !important;
  }
  .react-numeric-input > input {
    border-radius: 3px !important;
    padding-left: $input-padding-horizontal !important;
    border-color: $input-enabled-color !important;
    &:disabled {
      border-color: $input-disabled-color !important;
    }
  }
  .react-numeric-input > b {
    right: $input-padding-vertical !important;
  }
  input[type='checkbox'] {
    width: auto;
    background-color: transparent;
  }
}

.app-theme .invoice-container {
  background-color: #fff;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
}

.app-theme .invoice-actions-footer .btn.btn-remove {
  border-color: #aaa;
  color: #aaa;
}

.app-theme .invoice-actions h4,
.app-theme .invoice-settings h4 {
  font-size: 12px;
  text-transform: uppercase;
  border-bottom: 1px solid #333;
  color: #333;
  padding: 30px 0 5px 0;
}

.app-theme .invoice-settings {
  margin-bottom: 10px;
  @media screen and (max-width: 767px) {
    margin: 0;
  }
}

.app-theme .invoice-detail-body h2 small {
  color: #666;
}

/* SendEmail */
.app-theme .sendEmail {
  border: 1px solid #ececec;
  background-color: #f9f9f9;
}

/* Footer / FAQ */

/* faq */
.app-theme .faq {
  color: #555;
}

.app-theme .send-email .btn-email,
.app-theme .send-email .btn-email:hover {
  color: #fff;
}

/* snapshot render */
.item-row-description textarea {
  min-height: 84px;
}
.invoice-detail-notes textarea {
  min-height: 134px;
}

/* disable spin buttons */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

/* help */

.app-theme span.helper-message {
  color: #bbb;
}

.loading-indicator {
  text-transform: uppercase;
  align-self: center;
  font-size: 0.8em;
  .error {
    color: #c62828;
  }
}

.page-header {
  display: flex;
  word-break: break-word;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
  column-gap: 4px;
  h1 {
    margin-bottom: 0;
  }
}

.divider {
  margin: 0;
  &.margin {
    margin: $input-padding-vertical $input-padding-horizontal * 3;
    @media screen and (max-width: 767px) {
      margin: $input-padding-vertical $input-padding-horizontal;
    }
  }
}

.table {
  margin-bottom: 0;
}

.spacer {
  height: 32px;
}

.panel {
  background-color: #fff;
  border: 1px solid #ececec;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  padding-top: 13px;
  padding-bottom: 13px;
  border-radius: 4px;
  overflow-x: auto;
}

.text-error {
  color: #c62828;
}
