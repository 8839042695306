.invoice-actions {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-content: center;
  transition: ease-in 100ms;

  .btn-cancel {
    margin-left: 1em;
  }
  .btn.active:hover {
    box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.3), 0 0 0 rgba(255, 255, 255, 0.5);
  }
  .invoice-actions-export {
    display: flex;
    .btn {
      margin: 0 2px;
    }
  }
}

.sticky-outer-wrapper {
  width: 100%;

  .invoice-actions {
    @media screen and (max-width: 767px) {
      padding: 8px 16px;
    }
  }
}

.sticky {
  .invoice-actions {
    background-color: rgba(249, 249, 249, 0.9);
    border-bottom: solid 1px #efefef;
  }
}
