.report-list table {
  background-color: #fff;
}
.report-list table td,
.report-list table th {
  border: none;
  padding: 0.8rem;
}
.report-list table td {
  border-top: 1px solid #ececec;
  border-left-style: none;
  border-right-style: none;
}
.report-list table tbody tr:hover {
  background-color: rgba(60, 194, 121, 0.075);
}
.report-list table tbody tr.report-row-loading:hover,
.report-list table tbody tr.report-row-total:hover {
  background-color: #fff;
}

.report-list-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.report-list .btn.active,
.report-list .btn.active:hover {
  box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.3), 0 1px 0 rgba(255, 255, 255, 0.5);
}

.report-list .btn-link,
.report-list .btn-link:not([href]),
.report-list .btn-link:hover,
.report-list .btn-link:not([href]):hover {
  color: #3cc279;
  text-decoration: underline;
  cursor: pointer;
}

.paid-report-list-body tr > :first-child,
.client-report-list-body tr > :first-child,
.item-report-list-body tr > :first-child {
  padding-left: 21px;
}

.paid-report-list-body tr > :last-child,
.client-report-list-body tr > :last-child,
.item-report-list-body tr > :last-child {
  padding-right: 21px;
}

@media screen and (max-width: 374px) {
  .btn-group {
    flex: auto;
  }
  .btn {
    flex: 1 1 auto;
  }
}
