.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  border: 1px solid #f3f3f4;
  border-radius: 3px;
  padding: 6px 10px;
  width: 100%;
}

.input-group .react-autosuggest__input {
  position: relative;
  display: table-cell;
  z-index: 2;
  width: 100%;
  margin-bottom: 0;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.app-theme .input-group .input-group-btn .btn {
  border-radius: 0;
  margin: 0;
  background-color: #f9f9f9;
  border: 1px solid #ddd !important;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-left: -3px;
  border-left: none;
}
.app-theme .input-group .input-group-btn .btn:hover {
  border: 2px solid #555;
  background-color: #555;
}

.input-group .input-group-btn .btn i {
  margin: 0;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  background-color: #fff;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 34px;
  width: 100%;
  border: 1px solid #f3f3f3;
  border-top: none;
  background-color: #fff;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 6px 10px;
  border-bottom: 1px solid #f3f3f3;
}

.react-autosuggest__suggestion--highlighted {
  background-color: rgba(60, 194, 121, 0.075);
}
.react-autosuggest__suggestion .invoice-suggestion-option .invoice-suggestion-name,
.react-autosuggest__suggestion .invoice-suggestion-option .invoice-suggestion-meta {
  display: block !important;
  text-align: left !important;
}
.react-autosuggest__suggestion .invoice-suggestion-option .invoice-suggestion-name {
  font-weight: 500;
}
.react-autosuggest__suggestion .invoice-suggestion-option .invoice-suggestion-meta {
  font-size: 12px;
  color: #999;
}
