.inline-signup {
  border-top: 1px solid #ccc;
  padding: 15px 0;
  margin-bottom: -60px;
}

.inline-signup .focus-container {
  width: 460px;
  margin: 30px auto;
  padding: 0 15px;
}

.inline-signup .focus-footer {
  text-align: center;
}

.inline-signup .focus-footer .btn {
  margin-left: 20px;
}

.inline-signup h2 {
  text-align: center;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 15px;
}

.inline-signup h3 {
  text-align: center;
  font-size: 18px;
  line-height: 1.6em;
  margin-bottom: 15px;
}

.inline-signup .brand,
.inline-signup .btn.btn-cancel {
  display: none;
}

.signup-terms {
  padding-top: 5px;
  font-weight: 300;
  text-align: center;
}

.signup-terms a {
  color: #007bff;
}

.consent-management {
  display: flex;
  padding-top: 4px;
  padding-bottom: 4px;
}

.consent-checkbox {
  padding-top: 3px;
  padding-left: 2px;
  padding-right: 8px;
}

.showmore-content {
  display: none;
}

.showmore-link {
  color: #057bff;
}

#showmore:target .showmore-content {
  display: initial;
}

#showmore:target .showmore-trigger {
  display: none;
}
