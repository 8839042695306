.client-row {
  cursor: pointer;
}
.client-row.client-row-empty .client-col-name {
  color: #ccc;
}

.client-row .client-col-actions {
  text-align: right;
  width: 30px;
}

.client-row .icon-wrapper svg {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 auto;
}

.client-row-empty td {
  border-bottom: 0 !important;
  color: #999;
}

.client-row-empty td a {
  color: #999;
  margin: 0 5px;
}

.client-col-name p,
.client-col-email p,
.client-col-address p,
.client-col-phone p {
  word-wrap: break-word;
}

.client-col-name p,
.client-col-email p {
  max-width: 350px;
}

.client-col-address p,
.client-col-phone p {
  max-width: 250px;
}

.client-invoice-count {
  font-size: 1.15rem;
  color: #999;
  margin-top: 0.25rem;
}

@media screen and (max-width: 767px) {
  .client-col-name p,
  .client-col-email p,
  .client-col-address p,
  .client-col-phone p {
    max-width: 150px;
  }
}
