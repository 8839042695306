.invoice-payment input,
.invoice-payment .Select-control {
  max-width: 160px;
}
.invoice-payment .payment-history {
  width: 100%;
}
.invoice-payment .payment-history .invoice-summary-label {
  text-align: right;
}

.invoice-payment .payment-history {
  margin-top: 30px;
  border-top: 1px solid #ececec;
}
.invoice-payment .payment-history td {
  padding: 6px;
  border-bottom: 1px solid #ececec;
}
.invoice-payment .payment-history .payment-history-row th {
  padding-top: 45px;
}

@media only screen and (max-width: 768px) {
  .invoice-payment .payment-history-row label {
    margin-top: 30px;
  }
}
