.payment-markup-fees {
  @media (max-width: 768px) {
    h4 {
      font-size: 12px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      color: #333;
      padding: 30px 0 5px 0;
    }
  }

  .btn.btn-prime.btn-markup-payment-fees {
    background: #4d4d4d;
    transition: all 500ms ease;

    span {
      display: block;
      overflow: hidden;
      transition: all 500ms ease;
    }

    span.initial {
      height: auto;
      opacity: 1;
      width: auto;
    }

    span.success {
      color: #23ad71;
      height: 0;
      opacity: 0;
      width: 0;
    }

    &.animate {
      background: #f9f9f9;
      border-color: #f9f9f9;
      cursor: default;
      opacity: 1;

      & span.initial {
        height: 0px;
        opacity: 0;
        width: 0px;
      }

      & span.success {
        height: auto;
        opacity: 1;
        width: auto;
      }
    }
  }
}
