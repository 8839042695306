@use 'src/styles/modal-content' as mc;

.promo-modal-content {
  @extend %modal-content-base;

  header {
    @media screen and (max-width: 806px) {
      padding: 1rem 0;
    }
  }

  .promo-modal-body-container {
    padding: 2rem;
    font-size: 14px !important;
  }

  .promo-modal-content-footer {
    background-color: #f3f4f6;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 2rem;

    .promo-modal-footer-btn-container {
      $primary-background: linear-gradient(#555555, #444444);

      display: flex;
      justify-content: flex-end;
      gap: 8px;

      @mixin confirmButtonHover {
        background: $primary-background;
        opacity: 0.8;
      }

      @mixin focusOutline {
        outline: 1px solid rgb(130, 129, 129);
      }

      button {
        @include mc.buttonStyle;
      }

      button.promo-modal-reject-button {
        background-color: #ffffff;
        color: #555555;

        &:hover {
          background: #f5f5f5;
        }

        &:active,
        &:focus {
          @include focusOutline;
          background: #f0f0f0;
        }
      }

      button.promo-modal-confirm-button {
        background: $primary-background;
        color: #ffffff;
        min-width: 97px;
        max-height: 30px;

        &:hover {
          @include confirmButtonHover;
        }

        &:active,
        &:focus {
          @include confirmButtonHover;
          @include focusOutline;
        }
      }
    }
  }
}
