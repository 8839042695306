@import './mixins';

.app-theme-focus {
  background-color: #444;

  .focus-container {
    width: 360px;
    margin: 30px auto;
    padding: 0 16px;
  }

  @media screen and (max-width: 320px) {
    .focus-container {
      width: 320px;
      margin: 16px auto;
      padding: 0 8px;
    }
  }

  .footer {
    display: none;
  }

  .focus-header {
    text-align: center;
    h1,
    h2,
    h3 {
      @include smoothFont();
      border: 0;
      margin: 0;
      color: #fff;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
    }

    h1 {
      font-size: 2em;
      margin-bottom: 16px;
    }
    h2 {
      font-size: 2rem;
    }

    h3 {
      font-size: 1.2rem;
      margin-top: 8px;
    }

    .brand-logo {
      width: 40px;
      height: 40px;
      margin: 8px;
    }
  }

  .focus-body {
    margin-top: 16px;
    padding: 16px;
    background-color: #fff;
    border-radius: 3px;
    color: #333;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
    .help-text {
      padding: 8px 0;
      color: #999;
    }

    .btn {
      margin: 5px 0;
    }

    .btn.btn-cancel {
      display: block;
      width: 100%;
    }
    .stripe-checkout.loading .btn.btn-prime {
      background-color: #999;
      border-color: #999;
    }
    .stripe-checkout.loading .btn.btn-prime:hover {
      box-shadow: none;
      transform: scale(1);
      text-decoration: none;
    }

    .is-alert {
      position: static;
      padding: 0.5rem 0.75rem;
      margin-bottom: 1rem;
      border-radius: 3px;

      .container {
        padding: 0;
      }
      strong {
        display: block;
      }
    }
  }

  .focus-footer {
    @include smoothFont();
    color: #fff;
    text-align: center;
    padding: 15px 0;
    font-size: 14px;
    span {
      display: inline-block;
      padding: 5px;
    }

    .btn {
      display: inline-block;
      padding: 5px 7px;
      border-color: #fff;
    }
  }

  /* hide main alert */
  .app > .is-alert {
    display: none;
  }

  .alert-hidden {
    display: none;
  }
}
