.item-edit-body {
  padding: 45px;
  background-color: #fff;
  border: 1px solid #ececec;
  position: relative;
  min-height: 350px;
}

.item-edit-footer {
  padding: 1em 0;
  position: relative;
}

.item-edit-footer .confirm-delete-button {
  float: right;
}

.item-edit table {
  width: 100%;
}

.item-edit .item-address input {
  margin-bottom: 5px;
}

.item-edit .row-item-address input {
  margin-bottom: 6px;
}

.item-settings {
  margin-top: 30px;
}

.item-settings h4 {
  font-size: 14px;
  border-bottom: 1px solid #aaa;
  color: #666;
  padding: 30px 0 5px 0;
}

/*  item delete  */
.item-delete .item-edit-header,
.item-delete .item-edit-body {
  opacity: 0.6;
}
.item-deleted .item-edit-header,
.item-deleted .item-edit-body {
  opacity: 0.3;
}
