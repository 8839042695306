.welcome-alert {
  padding: 15px 0;
  background-color: #333;
  color: #fff;
}
.welcome-alert h1,
.welcome-alert h2,
.welcome-alert p,
.welcome-alert .welcome-alert-body {
  color: #fff;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
}
.welcome-alert h1 {
  font-size: 1.8rem;
  font-weight: 500;
}
.welcome-alert h2 {
  font-size: 1.4rem;
  font-weight: 500;
}
.welcome-alert .welcome-alert-body {
  font-size: 1.2rem;
  margin: 15px 0;
}

.welcome-alert .btn.btn-light {
  font-weight: 500;
  font-size: 14px;
}
