@import '../styles/mixins';

.photo-drop .guide-message,
.photo-drop .error-message {
  background-color: rgba(255, 255, 255, 0.8);
}
.photo-drop .active {
  border: 1px solid #ddd;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.18);
}
.photo-drop .error {
  color: red;
}

.photo-drop {
  @include inputCommons();
  position: relative;
  padding: 0 !important;
  margin-bottom: 30px;
}

.photo-drop-zone {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 180px;
  min-height: 90px;
  text-align: center;
  border: none !important;

  box-shadow: none;
  transition: background-color 200ms ease-out 0ms, opacity 80ms ease-out 0ms,
    transform 80ms ease-out 0ms, box-shadow 80ms ease-out 0ms;
}

.photo-drop .guide-message,
.photo-drop .upload-message {
  font-size: 12px;
  color: #333;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  padding: 5px;
  display: none;
  text-rendering: optimizelegibility;
  background-color: rgba(255, 255, 255, 0.9);
}
.photo-drop .upload-message i,
.photo-drop .guide-message i {
  margin-right: 5px;
}
.photo-drop .upload-message {
  top: 0px;
}
.photo-drop.uploading .upload-message {
  display: inline-block;
}
.photo-drop .guide-message {
  bottom: 0px;
}
.photo-drop:hover .guide-message {
  display: inline-block;
}
.photo-drop:hover .photo-drop-zone.disabled .guide-message {
  display: none;
}

.photo-drop.active {
  opacity: 1;
  transform: scale(1.05);
}
.photo-drop img {
  width: 190px;
}
.photo-drop h3 {
  padding: 20px 15px 0;
}
.photo-drop span {
  display: block;
}

/* preview */
.photo-drop .photo-drop-preview {
  min-height: 150px;
}

.photo-drop .photo-drop-preview img {
  width: 194px;
}

.photo-drop {
  .dropzone-symbols {
    display: flex;
    align-items: center;
    .logo-image {
      margin: 0 5px;
    }
  }
  .btn-delete-logo {
    width: 15px;
    height: 15px;
    background-color: rgba(255, 255, 255, 0.5);
    padding-left: 4px;
  }
  .delete-logo-container {
    position: absolute;
    z-index: 10;
    top: 6px;
    right: 7px;
    cursor: pointer;
  }
}

@media screen and (min-width: 768px) {
  .photo-drop {
    .btn-delete-logo {
      display: none;
    }
    &:hover {
      .btn-delete-logo {
        display: inline-block;
      }
    }
    .delete-logo-container:hover {
      .btn-delete-logo {
        transform: scale(1.15);
        color: #dc2626;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .photo-drop {
    margin-bottom: 0;
    margin-left: 10px;
    img {
      width: 110px;
    }
    .guide-message {
      padding: 3px;
      font-size: 0.6rem;
      display: block;
    }
    .photo-drop-preview {
      min-height: auto;
      img {
        width: 110px;
      }
    }
    .dropzone-symbols {
      padding: 16px;
    }
    .btn-delete-logo {
      display: inline-block;
    }
  }
  .photo-drop-zone {
    min-width: 90px;
    min-height: 30px;
  }
}
