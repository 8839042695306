.cancel-screen {
  font-family: Quicksand, sans-serif;

  img {
    max-width: 250px;
  }

  h1 {
    font-size: 30px;
    padding: 30px 0;
    color: #1f2937;
    font-style: 'bold';
  }

  .cancel-content {
    font-size: 15px;
    color: #1f2937;
    line-height: 22px;
    padding: 0 5px 40px 5px;
  }

  .btn-round {
    border-radius: 40px;
    font-size: 15px;
    font-weight: 600;
  }
}

.value-props-screen {
  .cancel-button {
    text-underline-offset: 2px;
  }
}
