.client-suggest {
  width: 100%;
}

.clear-client-autosuggest {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  .btn {
    min-width: auto;
    font-size: unset;
    padding: 6px 12px;
    border-radius: 3px;
  }
}

.client-suggest .react-autosuggest__suggestions-container--open {
  border: 1px solid #ccc;
  border-top: none;
  top: 36px;
}
.client-suggest .react-autosuggest__input {
  border-radius: 3px !important;
}
.client-suggest .invoice-suggestion-option .invoice-suggestion-name,
.client-suggest .invoice-suggestion-option .invoice-suggestion-meta {
  line-height: 1.5rem !important;
}

.client-suggest .react-autosuggest__input.react-autosuggest__input--open {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.input-group .input-group-btn .btn .fa {
  padding: 0;
}
