
.radio-button {
  display: none;
}

.radio-button + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

.radio-button + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 1px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #ccc;
  background-color: transparent;
}

.radio-button:checked + label::before {
  background-color: #fff;
  border: 5px solid #ff5e2d;
}