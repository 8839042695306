.new-features {
  line-height: 2rem;
}

.new-features h4 {
  margin-bottom: 0.6rem;
}

.new-features ul {
  padding: 0 2rem;
}
