.client-list table {
  background-color: #fff;
}
.client-list table td,
.client-list table th {
  border: none;
  border-bottom: 1px solid #ececec;
}
.client-list table td {
  border-top: 1px solid #ececec;
  border-left-style: none;
  border-right-style: none;
}
.client-list table tbody tr:hover {
  background-color: rgba(60, 194, 121, 0.075);
}
.client-list table tbody tr.client-row-loading:hover,
.client-list table tbody tr.client-row-total:hover {
  background-color: #fff;
}

// first element child of .client-row
.client-row > :first-child {
  padding-left: 21px;
}

// last element child of .client-row
.client-row > :last-child {
  padding-right: 21px;
}

.client-row td {
  padding-top: 8px;
  padding-bottom: 8px;
}

.client-list .invoice-head-invoice {
  padding-left: 21px;
}

.client-list table .invoice-row-loading td span {
  padding: 6px 12px;
  color: #999;
}

.client-list .btn-link,
.client-list .btn-link:not([href]),
.client-list .btn-link:hover,
.client-list .btn-link:not([href]):hover {
  color: #3cc279;
  text-decoration: underline;
  cursor: pointer;
}

.client-list-footer {
  padding: 0.8rem;
}

.client-list.loading table {
  opacity: 0.5;
  transition: opacity 0.1s ease-out;
}

.client-list-actions {
  display: flex;
}

.client-list {
  overflow-x: hidden;
}

.client-list .client-row:last-child {
  td {
    border-bottom: 0 !important;
  }
}

@media (max-width: 768px) {
  .client-search-container {
    column-gap: 4px;

    .client-list-actions {
      justify-content: right;
      width: 100%;
      display: flex;
    }

    input {
      flex: 1;
    }

    h1 {
      display: none;
    }
  }
}
