.btn.btn-prime.btn-prime-orange {
  background: linear-gradient(#ff5722, #de5023);
  border: 1px solid #ff3d00;
}
.btn.btn-prime.btn-prime-orange:hover {
  background: linear-gradient(#ff764b, #dc5328);
  color: #fff;
}
.btn.btn-prime.btn-prime-orange:disabled {
  border: 1px solid #999;
}
