.footer {
  padding-bottom: 20px;
  border-top: 1px solid #ececec;
  margin-top: 100px;
  width: 100%;
}

.footer .footer-body {
  transition: opacity 200ms;
  opacity: 1;
}
.footer .footer-body:hover {
  opacity: 1;
}

.footer .footer-footer {
  padding: 20px 0;
}

.footer .footer-body ul {
  padding: 0;
}

.footer h4,
.footer a,
.footer span {
  line-height: 18px;
  font-size: 14px;
  padding: 5px 0 0;
}

.footer h4 {
  margin-top: 10px;
  color: #555;
  font-weight: 500;
}

.footer li a,
.footer li span {
  color: #999;
}

.footer li a:hover,
.footer li a:active,
.footer li a:focus,
.footer li a:hover i {
  color: #555;
  text-decoration: none;
}

.footer li i {
  padding: 5px 5px 0 0;
  color: #999;
}

.footer li {
  padding: 5px 0 0 0;
  overflow: hidden;
}
