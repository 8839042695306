@import '../../styles/mixins';

.invoice-row {
  overflow-x: hidden;
  cursor: pointer;

  .invoice-col-balance {
    text-align: right;
    font-size: inherit;
  }
  .invoice-col-client {
    font-weight: 500;
    font-size: inherit;

    p {
      width: 400px;
      word-wrap: break-word;
    }

    @media screen and (max-width: 767px) {
      p {
        width: 250px;
      }
    }

    @media screen and (max-width: 467px) {
      p {
        width: 140px;
      }
    }
  }

  .invoice-col-state .invoice-due {
    font-size: 0.8rem;
    padding: 0 5px;
  }
  .badge {
    @include smoothFont();
    font-size: 0.7rem;
    color: #fff;
    text-transform: uppercase;
    padding: 2px 5px;
    background-color: #999;
    border-radius: 3px;
    font-weight: 500;
    .badge-sent {
      background-color: #333;
    }
    .badge-paid {
      background-color: #5fad06;
    }
    .badge-late {
      background-color: #d1011b;
    }
  }

  @media screen and (max-width: 767px) {
    font-size: 1rem;
    .invoice-col-id,
    .invoice-col-client,
    .invoice-col-balance,
    .invoice-col-state,
    .invoice-col-date {
      font-size: 1em;
      font-weight: normal;
    }
  }
}

.invoice-list table .invoice-row-total td {
  text-align: right;
  // border-top: 1px solid #ececec;
  border-bottom: none;
  font-size: 1.4em;
  font-weight: 500;
  padding-top: 16px;
  padding-bottom: 16px;
  @media screen and (max-width: 767px) {
    font-size: 1em;
  }
}

.invoice-list table .invoice-row .icon-wrapper {
  svg {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 auto;
  }
}

.invoice-list table .invoice-row-total .invoice-row-total-label {
  float: left;
}

.invoice-list table .invoice-row-loading td span {
  padding: 6px 12px;
  color: #999;
}

.invoice-row-empty td {
  border-bottom: 0 !important;
  color: #999;
  padding-left: 21px;
  padding-right: 21px;
}

.invoice-row-empty td a {
  color: #999;
  margin: 0 5px;
}

.invoice-balance-paid, .invoice-estimate-signed {
  color: #5fad06;
  display: block;
  font-weight: bold;
}

// first element child of .invoice-row
.invoice-row > :first-child {
  padding-left: 21px;
}

// last element child of .invoice-row
.invoice-row > :last-child {
  padding-right: 21px;
}

.invoice-row td {
  padding-top: 8px;
  padding-bottom: 8px;
}
