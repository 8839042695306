.invoice-email .invoice-email-message {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 6px 10px;
  width: 100%;
}

.abtest-invoice-email-sidebar {
  h3 {
    font-size: 12px;
    text-transform: uppercase;
    border-bottom: 1px solid #333;
    color: #333;
    padding: 0 0 5px 0;
    margin-bottom: 5px;
  }
  .invoice-email {
    border: none;
    padding: 0;
  }

  .invoice-email-body {
    .btn-close,
    .btn-cancel {
      display: none;
    }
    .btn-prime,
    .btn-prime:hover,
    .btn-prime:disabled,
    .btn-prime:disabled:hover {
      width: 100%;
      margin-right: 0;
    }
  }
}
