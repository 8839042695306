@mixin buttonStyle {
  padding: 8px 12px;
  outline: none;
  border: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-style: medium;
  font-size: 12px;
  border-radius: 3px;
  text-transform: capitalize;
  transition: all 0.2s ease-in-out;
}

%modal-content-base {
  max-width: 480px;
  width: 100%;
  position: relative;
  height: 100%;

  @media screen and (max-width: 480px) {
    max-width: 100%;
  }

  // So the modal contents can vertically scroll on mobile viewports.
  @media only screen and (max-height: 663px) {
    height: 75vh;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 6.5px;

    h3 {
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-size: 17.5px;
      font-weight: 500;
      text-align: center;
      text-transform: capitalize;
      padding-left: 24px;
      padding-right: 24px;
      color: #333333;
    }

    button {
      background: none;
      border: none;
      padding: 13px;
      height: 100%;

      .icon-x {
        width: 15px;
        height: 15px;
        fill: #9ca3af;
      }

      &:hover {
        transition: all 0.2s ease;
        border-radius: 4px;
        transform: scale(1.15);
      }
    }
  }

  main {
    section {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .data-entry {
      padding: 32px 24px 24px 24px;
      height: inherit;

      input,
      textarea {
        border-color: #9ca3af;
        border-radius: 4px;
        color: #454545;
        font-size: 14px;
        font-weight: 400;
        font-family: Roboto, Helvetica, Arial, sans-serif;
        padding: 12px 16px;
      }

      textarea {
        min-height: 80px;
        max-height: 80px;
      }

      div:nth-child(2) {
        margin-top: 24px;
      }
    }
  }

  .modal-content-footer {
    display: flex;
    justify-content: flex-end;
    background-color: #f3f4f6;
    gap: 8px;
    padding: 2rem;

    button {
      @include buttonStyle;
    }

    button:nth-child(1) {
      background-color: #ffffff;
      color: #555555;

      &:hover {
        background: #f5f5f5;
      }

      &:active,
      &:focus {
        background: #f0f0f0;
        outline: 1px solid rgb(130, 129, 129);
      }
    }

    button:nth-child(2) {
      color: #ffffff;
      background: linear-gradient(#555555, #444444);

      &:hover {
        opacity: 0.8;
      }

      &:active,
      &:focus {
        opacity: 0.8;
        outline: 1px solid rgb(130, 129, 129);
      }
    }
  }
}
