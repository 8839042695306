.payment-row .table.invoice-table-edit tr td {
  border-bottom: 1px solid #efefef;
}

/* delete */
.payment-row.payment-row-delete {
  background-color: #fafafa;
}
.payment-row.payment-row-delete .payment-row-summary,
.payment-row.payment-row-delete .payment-row-amount {
  opacity: 0.7;
}
.payment-row .payment-row-amount {
  text-align: right;
  width: 120px;
}
.payment-row.payment-row-delete .payment-row-amount .currency-amount {
  text-decoration: line-through;
}
.payment-row .payment-row-actions {
  text-align: center;
  width: 50px;
}
.payment-row .payment-row-actions .btn i {
  padding: 0;
}
.payment-row-date {
  display: block;
}

.payment-row-notes {
  display: block;
}

.payment-row .payment-row-actions .btn {
  background: none;
  border: 2px solid #555;
  padding: 4px 8px;
  margin: 2px 0;
  transition: transform 0.2s ease-out;
  transform: scale(1);
}
.payment-row .payment-row-actions .btn:hover {
  transform: scale(1.05);
  background: none;
}
