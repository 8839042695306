.item-list table {
  background-color: #fff;
}

.item-list table td,
.item-list table th {
  border: none;
  border-bottom: 1px solid #ececec;
}

.item-list .item-row:last-child {
  td {
    border-bottom: 0 !important;
  }
}

.item-list table td {
  border-top: 1px solid #ececec;
  border-left-style: none;
  border-right-style: none;
}

.item-list table tbody tr:hover {
  background-color: rgba(60, 194, 121, 0.075);
}

.item-list table tbody tr.client-row-loading:hover,
.item-list table tbody tr.client-row-total:hover {
  background-color: #fff;
}

// first element child of .item-row
.item-row > :first-child {
  padding-left: 21px;
}

// last element child of .item-row
.item-row > :last-child {
  padding-right: 21px;
}

.item-row td {
  padding-top: 8px;
  padding-bottom: 8px;
}

.item-list .invoice-head-invoice {
  padding-left: 21px;
}

.item-list table .invoice-row-loading td span {
  padding: 6px 12px;
  color: #999;
}

.item-list .thead {
  font-weight: 500;
}
.item-list .invoice-head-rate {
  text-align: right;
}
.item-list .btn-link,
.item-list .btn-link:not([href]),
.item-list .btn-link:hover,
.item-list .btn-link:not([href]):hover {
  color: #3cc279;
  text-decoration: underline;
  cursor: pointer;
}

.item-list-footer {
  padding: 0.8rem;
  color: #bbb;
}
.item-list-footer i {
  padding-right: 5px;
}

.item-list.loading table {
  opacity: 0.5;
  transition: opacity 0.1s ease-out;
}

.item-list-actions {
  display: flex;
}

@media (max-width: 768px) {
  .item-search-container {
    column-gap: 4px;

    .item-list-actions {
      justify-content: right;
      width: 100%;
      display: flex;
    }

    .btn {
      flex: none;
    }

    input {
      flex: 1;
    }

    h1 {
      display: none;
    }
  }
}

@media (max-width: 384px) {
  .item-search-container {
    .btn {
      font-size: 12px;
    }
    input {
      font-size: 10px;
      overflow: hidden;
    }
  }
}
