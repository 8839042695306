.invoice-item-list .table th,
.invoice-item-list .table td {
  padding: 0.25rem;
}

.invoice-item-list .table th {
  padding: 0.7rem 0.5rem;
  border: none;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
}
.invoice-item-list .table td {
  border: none;
  padding-top: 8px;
  padding-bottom: 0px;
}

.invoice-item-list .invoice-summary-value,
.invoice-item-list .invoice-summary-label,
.invoice-item-list .table th {
  text-align: right;
}
.invoice-item-list .table th.invoice-detail-summary,
.invoice-item-list .table td.item-row-summary {
  text-align: left;
  width: 100%;
}
.invoice-item-list .table td.invoice-detail-margin,
.invoice-item-list .table th.invoice-detail-margin {
  width: 8px;
  max-width: 8px;
}

.invoice-item-list .table th.invoice-detail-tax {
  text-align: center;
}
.invoice-item-list .table tbody + tbody {
  border: none;
}

.invoice-totals,
.invoice-balance {
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
}

.invoice-item-list .table .invoice-adjustments td,
.invoice-item-list .table .invoice-totals td {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.invoice-item-list .table .invoice-adjustments tr:first-child td,
.invoice-item-list .table .invoice-totals tr:first-child {
  padding-top: 1rem;
}

.invoice-item-list table thead tr {
  text-transform: uppercase;
  padding: 0.8rem 0;
  font-weight: 700;
  border-top: 1px solid;
  border-bottom: 1px solid;
  font-size: 1rem;
}

@media only screen and (max-width: 767px) {
  .invoice-item-list .table .invoice-items .item-row-actions {
    width: 50px;
  }
  .invoice-item-list .table td.item-row-summary {
    text-align: right;
  }
  .invoice-item-list .table .invoice-summary-label,
  .invoice-item-list .table .invoice-summary-padding,
  .invoice-item-list .table .invoice-detail-margin {
    display: none;
  }

  .invoice-item-list .table .invoice-totals td {
    padding: 0;
  }

  .invoice-item-list .table {
    border-top: none;
  }
  .invoice-item-list .table table,
  .invoice-item-list .table thead,
  .invoice-item-list .table tbody,
  .invoice-item-list .table th,
  .invoice-item-list .table td,
  .invoice-item-list .table tr {
    display: block;
  }
  .invoice-item-list .table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .invoice-item-list .table tr {
    position: relative;
  }
  .invoice-item-list .table .item-row-description textarea {
    margin: 0;
  }
  .invoice-item-list .table td {
    border: none;
    position: relative;
    padding: 6px 0px;
    padding-left: 20%;
    white-space: normal;
    text-align: right;
  }

  .invoice-item-list .table .item-row-rate input {
    width: 100%;
  }
  .invoice-item-list .table .item-row-tax {
    text-align: right;
  }
  .invoice-item-list .table .item-row-tax .btn-checkbox {
    display: inline-block;
  }

  .invoice-item-list .table td:before {
    position: absolute;
    top: 3px;
    left: 0px;
    width: 25%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    content: attr(data-label);
  }

  .invoice-item-list .table .item-row td:before {
    top: 8px;
  }

  .invoice-item-list .table td.item-row-summary {
    padding-bottom: 0;
  }

  .invoice-item-list .table .currency {
    line-height: 2.2rem;
  }

  .invoice-item-list .table .item-row-actions .btn {
    display: inline;
  }
}
