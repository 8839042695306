@import '../../styles/variables';

// variables to ensure alignment to invoice-detail elements
$tax-column-width: 40px;
$invoice-detail-margin: 13px;

.invoice-totals-container {
  position: relative;
  width: 100%;
  margin-top: 8px;
  overflow: auto;
  .invoice-totals-row {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    &.bold {
      font-weight: 500;
      font-size: 1.1rem;
    }
    &.desktop {
      padding-right: $tax-column-width;
      .invoice-summary-value {
        padding: 0 0.25rem;
      }
    }
    .invoice-preview &.desktop {
      padding-right: $invoice-detail-margin;
    }
  }
  .invoice-summary-value {
    min-width: 130px;
    text-align: right;
  }
  .invoice-summary-label {
    margin-left: 0;
    text-align: right;
  }
  .add-input-field-button {
    position: absolute;
    top: 0px;
    left: 48px;
    margin: 0;
  }
  &.no-tax {
    .invoice-totals-row.desktop {
      padding-right: $invoice-detail-margin;
    }
  }
}
