.item-row .item-col-taxable {
  cursor: pointer;
}

.item-row.item-row-empty .item-col-code {
  color: #ccc;
}

.item-row .item-col-taxable {
  width: 50px;
  text-align: center;
}

.item-row .item-col-rate {
  width: 100px;
  text-align: right;
}

.item-row .item-col-description {
  min-width: 120px;
}

.item-row .item-unit {
  font-size: 14px;
}

@media (max-width: 768px) {
  .item-row .localized-number {
    font-size: 12px;
  }
  .item-row .item-unit {
    font-size: 12px;
  }
}
