@import '../../styles/variables';
@import '../../styles/mixins';

.add-input-field-button {
  @include smoothFont();
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  padding: $input-padding-vertical $input-padding-horizontal;
  margin-top: $input-padding-vertical;
  border-radius: 3px;
  background-color: #555;
  color: white;
  outline: none !important;
  white-space: pre;

  span {
    margin-left: 5px;
  }

  &.inverted {
    box-sizing: border-box;
    background-color: transparent;
    color: #555;
    box-shadow: 0 0 0px 1px #ccc;
  }
  .invoice-detail-header & {
    margin-right: calc($input-padding-horizontal / 2);
  }
  .invoice-item-deletion & {
    margin-left: calc($input-padding-horizontal / 2);
  }
}

@media screen and (min-width: 768px) {
  .add-input-field-button {
    background: linear-gradient(#f7f7f7, #e9e9e9);
    border: 1px solid #ddd;
    padding: 4px 8px;
    color: #666;
    cursor: pointer;
    &:hover {
      background: linear-gradient(#fefefe, #f1f1f1);
    }
  }
}
