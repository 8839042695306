@media only screen and (min-width: 768px) {
  .item-row-quantity input {
    max-width: 100px;
  }
  .item-row-rate input {
    max-width: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .invoice-detail-body {
    min-height: 100%;
    h2 {
      font-size: 2rem;
    }
  }
  .invoice-footer .btn {
    width: 100%;
    margin: 3px 0;
  }
}
