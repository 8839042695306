.input-with-label-wrapper {
  margin-bottom: 8px;

  .label {
    &.with-baseline {
      align-self: baseline;
      padding-top: 8px;
      padding-right: 8px;
      word-wrap: break-word;
    }
  }
  .content {
    position: relative;
  }
  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
  }
  .label {
    & label {
      margin-bottom: 0;
    }
    @media (min-width: 768px) {
      min-width: 25%;
    }
    @media (max-width: 767px) {
      padding-top: 8px;
    }
  }
  .content {
    width: 100%;
  }
}
