.paypal-promotion-box-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paypal-promotion-box {
  background: #ffffff;
  border: 1px;
  border-style: solid;
  border-color: #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 22px 40px;
  margin-bottom: 10px;
  max-width: 255px;

  .btn-payment-setup {
    min-width: fit-content;
  }
}

.paypal-promotion-box.payment-modal {
  margin-bottom: 0;
  border: none;
}

.paypal-promotion-box .is-logo {
  height: 34px;
  margin-right: 3px;
}

.paypal-promotion-box .paypal-logo {
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-left: 5px;
}

.paypal-promotion-box .text-header-container {
  height: 15px;
  margin: 9px 0px;
  display: flex;
  align-items: center;
  padding: 2px 4px;
  background: #ff5721;
  border-radius: 2px;
}

.paypal-promotion-box .text-header {
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.paypal-promotion-box .text-body {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 145%;
  color: #000000;
  padding-top: 8px;
}

.paypal-promotion-box.invoice-editor .text-body {
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 17px;
  }
}

.paypal-promotion-box .note-container {
  margin-top: 10px;
}

.paypal-promotion-box .text-note-onboarded {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 145%;
  color: #000000;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5px;
  font-weight: bold;
}

.paypal-promotion-box.invoice-editor .text-note-onboarded {
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 10px;
    margin-right: -10px;
  }
}

.paypal-promotion-box .text-note-onboarded .text-link {
  color: #2e77bb;
}

.paypal-promotion-box .text-note {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 145%;
  color: #919191;
  padding: 10px 0;
}

.paypal-promotion-box .onboarding-button {
  background: linear-gradient(#ff5e2d, #ff5722);
  color: #ffffff;
  border-radius: 2px;
  border: none;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  margin-top: 12px;
}

.paypal-promotion-box .onboarding-button:hover {
  background: linear-gradient(#ff764c, #ff5722);
  color: #ffffff;
  border-radius: 2px;
}

.paypal-promotion-box.invoice-editor .onboarding-button {
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 10px;
  }
}

.paypal-promotion-box .paypal {
  height: 15px;
}

.paypal-promotion-box .number-bullet {
  size: 80%;
}

.paypal-promotion-box .pending-onboarded-button {
  background: linear-gradient(#ff5e2d, #ff5722);
  color: #ffffff;
  border-radius: 2px;
  border: none;
  font-family: Quicksand;
  font-style: normal;
  margin-top: 12px;
  font-weight: 600;
}

.paypal-promotion-box .pending-onboarded-button:hover {
  background: linear-gradient(#ff764c, #ff5722);
  color: #ffffff;
  border-radius: 2px;
}
