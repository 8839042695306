@import '../../styles/variables';

.invoice-items-list {
  margin-bottom: $input-padding-vertical;
}
.invoice-item-container {
  padding-top: $input-padding-vertical;
  .numbers-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: $input-padding-vertical;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .numbers-container::-webkit-scrollbar {
    display: none;
  }
  .price-quantity {
    display: flex;
    align-items: center;
    .react-numeric-input > input {
      transition: color 500ms ease;
      &.animate {
        color: #23ad71;
      }
    }
  }
  .multiplication-sign {
    margin: 0 calc($input-padding-horizontal / 2);
  }
  .invoice-item-actions {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
