@import 'src/styles/modal-content';

.invoice-photo-grid {
  width: 100%;

  ul {
    padding: 0;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fit, 230px);
  }
}

.invoice-photo {
  font-style: Roboto, Helvetica, Arial, sans-serif;
  font-size: 12px;
  max-width: 232px;

  article {
    padding: 16px;
    outline: 1px solid #e5e7eb;
    border-radius: 4px;
    width: 100%;
  }

  header {
    object-fit: cover;
    aspect-ratio: 16 / 9;
    height: 110px;
    margin-bottom: 10px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  strong {
    font-weight: 700;
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  p {
    padding-top: 4px;
    font-weight: 400;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .invoice-photo-action-footer {
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 28px;

    .danger {
      color: #ef4444;
      font-size: 12px;
    }
  }

  button {
    background: none;
    border: none;
    padding: 6px 7px;

    &:hover,
    &:focus {
      background: #f5f5f5;
      transition: all 0.2s ease-in-out;
      border-radius: 4px;
    }
  }
}

.invoice-photo-add {
  position: relative;
  padding: 0;

  button {
    width: 171px;
    height: 90px;
    padding: 36px 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    background: none;
    outline: 1px solid #aaa;
    border: none;

    &:hover {
      background: #f5f5f5;
      transition: all 0.2s ease-in-out;
    }

    &:active,
    &:focus {
      background: #f0f0f0;
      transition: all 0.2s ease-in-out;
      outline: 1px solid rgb(130, 129, 129);
    }
  }

  p {
    text-transform: capitalize;
    text-align: left;
    width: 100%;
    padding: 0 0 0 7px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #454545;
  }

  svg {
    width: 24px;
    height: 18px;
  }
}

.invoice-photo-modal-content {
  @extend %modal-content-base;

  @media only screen and (max-width: 768px) {
    height: 90vh;
  }

  main {
    .invoice-photo-image-boundary {
      background: #f3f4f6;
    }

    section {
      height: 350px;

      @media only screen and (min-width: 768px) {
        width: 480px;
      }
    }
  }
}
