.dev-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  color: #333;
  font-size: 10px;
  z-index: 999;
}
.dev-footer table td:first-child {
  font-weight: 500;
}
.dev-footer table td {
  min-width: 30px;
  padding: 2px 10px;
}
.dev-footer .app-version {
  font-weight: 200;
  padding: 2px 10px;
  text-transform: uppercase;
}

.dev-footer a {
  text-decoration: underline;
}

.dev-footer-body {
  padding: 0 5px;
  background-color: rgba(255, 255, 255, 0.9);
}

.dev-footer-body table {
  margin: 5px 0;
}

.dev-footer-head {
  padding: 2px 5px;
  background-color: rgba(255, 255, 255, 0.2);
}
.dev-footer-head a {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
}

.dev-footer table.errors caption {
  caption-side: top;
  color: #333;
  font-weight: 500;
  padding: 2px 10px 3px;
  white-space: nowrap;
}
