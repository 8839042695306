.invoice-header {
  padding: 30px 45px;
  border: 1px solid #ddd;
  border-bottom: none;
  background-color: #f9f9f9;
  position: relative;
}
.invoice-header h3 {
  margin: 0 0 15px;
  font-size: 1.5rem;
}

.invoice-header textarea,
.invoice-header input {
  color: #55595c;
  width: 100%;
  resize: none;
}
.invoice-header .btn-submit {
  margin-right: 15px;
}
.invoice-header .btn-close {
  position: absolute;
  top: 10px;
  right: 15px;
}
.invoice-header .btn-close i {
  color: #aaa;
}

@media only screen and (max-width: 767px) {
  .invoice-header {
    padding: 15px;
  }
}
