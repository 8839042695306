.payment-instructions {
  .payment-instruction {
    margin: 0.4rem 0;
    @media only screen and (max-width: 767px) {
      margin: 0.6rem 0;
    }
  }

  .payment-instruction-label {
    padding-right: 1rem;
    min-width: 16rem;
    display: inline-block;
    font-weight: 500;
    @media only screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 0.4rem;
    }
  }
  .payment-instruction-header {
    font-size: 14px !important;
    font-weight: 500;
    margin: 16px 0;
  }
}
