.currency-mismatch-body .text-note {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 145%;
  color: #919191;
  padding-left: 5px;
}

.currency-mismatch-body {
  display: flex;
  flex-direction: row;
}

.btn-link {
  cursor: pointer;
}
