@import 'src/styles/modal-content';

.surcharge-consent-modal-content {
  @extend %modal-content-base;

  @media screen and (max-height: 663px) {
    @apply h-auto;
  }

  header {
    h3 {
      @apply text-left;

      @media screen and (max-width: 400px) {
        @apply px-6 py-5;
      }
    }
  }

  main {
    section {
      img.surcharge-consent-img {
        @apply border-solid border-0 border-t border-gray-200;
      }
    }
  }

  .surcharge-consent-intro {
    @apply leading-8 p-8;

    p {
      @apply py-0 mt-8;
    }

    a {
      @apply text-blue-500;
    }

    @media screen and (max-width: 400px) {
      @apply overflow-y-scroll h-48;
    }
  }

  .modal-content-footer {
    @apply p-0 flex-row;
  }
}
