.client-edit-body {
  padding: 45px;
  background-color: #fff;
  border: 1px solid #ececec;
  position: relative;
  min-height: 350px;
}

.client-edit-footer {
  padding: 15px 0;
}

.client-edit-footer .confirm-delete-button {
  float: right;
}

.client-edit table {
  width: 100%;
}

.client-edit .client-address input {
  margin-bottom: 5px;
}
.client-edit .row-client-address input {
  margin-bottom: 6px;
}

/*  client delete  */
.client-delete .client-edit-header,
.client-delete .client-edit-body {
  opacity: 0.6;
}
.client-deleted .client-edit-header,
.client-deleted .client-edit-body {
  opacity: 0.3;
}
