.is-alert {
  z-index: 30;
  position: relative;
  border-radius: 0;
  border-top: none;
  border-right: none;
  border-left: none;

  display: flex;
  align-content: center;
  justify-content: center;

  min-height: 50px;

  transition: all 0.15s ease-in;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: pre-wrap;
    & > div {
      padding: 8px 0;
      flex: 1 1 auto;
    }
  }

  strong {
    padding-right: 5px;
    font-weight: 500;
  }

  a {
    text-decoration: underline;
  }

  .btn.btn-close {
    padding: 0 16px;
    border: none;
    background: none;
    min-width: 0;
  }
  .btn.btn-close:not([href]):hover,
  .btn.btn-close:hover {
    background-color: transparent;
    opacity: 1;
    box-shadow: none;
    border: none;
  }

  &.alert-danger .btn.btn-close:hover,
  &.alert-danger .btn.btn-close {
    color: #a94442;
  }

  &.alert-success .btn.btn-close:hover,
  &.alert-success .btn.btn-close {
    color: #3c763d;
  }

  @media screen and (max-width: 767px) {
    &.navbar {
      position: fixed;
      top: 0;
      z-index: 101;
    }
    &.no-navbar {
      position: relative;
    }
    width: 100%;
    transform: translateY(0);
    opacity: 1;
  }
}

.alert-hidden {
  @media screen and (min-width: 768px) {
    display: none;
  }
  @media screen and (max-width: 767px) {
    min-height: 0 !important;
    transform: translateY(-100%);
    opacity: 0;
  }
}
