@import '../../styles/variables';

.invoice-detail {
  -webkit-transition: width 0.5s, height 0.5s; /* Safari */
  transition: width 0.5s, height 0.5s;
  margin-bottom: 130px;
  .invoice-address {
    .input-with-label-wrapper:last-child {
      margin-bottom: 0;
    }
  }
}

.invoice-detail-company span,
.invoice-detail-client span {
  display: block;
}

.item-row-total {
  font-weight: inherit;
}

.invoice-detail-body {
  h1,
  h2,
  h3,
  h4,
  p {
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
  }

  padding-top: $input-padding-vertical * 4;
  padding-bottom: $input-padding-vertical * 4;

  .content-block {
    padding-left: $input-padding-horizontal * 3;
    padding-right: $input-padding-horizontal * 3;
  }
  @media screen and (max-width: 767px) {
    padding-top: $input-padding-vertical;
    padding-bottom: $input-padding-vertical;
    .content-block {
      padding-left: $input-padding-horizontal;
      padding-right: $input-padding-horizontal;
    }
  }
}
.invoice-detail-body h2 {
  font-size: 2.6rem;
  font-weight: 500;
  margin-bottom: 30px;
  width: 100%;
  display: 'inline-block';
}
.invoice-detail-body h2 input {
  padding: 6px 10px;
  border-radius: 3px;
  width: 100%;
}
.invoice-detail-body h3 {
  margin-bottom: 1rem;
}

.loading-label,
.saving-label {
  text-transform: uppercase;
  font-size: 12px;
  padding: 0 5px;
}

.invoice-detail-list {
  list-style: none;
  padding: 0;
}

.invoice-detail-actors {
  border-bottom: 2px solid #ececec;
  padding-bottom: 40px;
  margin-bottom: 40px;
  min-height: 130px;
}

.invoice-detail-status {
  margin-bottom: 40px;
  clear: right;
}

.invoice-detail-about {
  border: 0;
}

.invoice-detail-about tr td {
  padding: 0.25rem;
  padding-left: 0;
}

.invoice-detail-about tr td:first-child {
  width: 70px;
}

.invoice-detail-body {
  position: relative;
  min-height: 1050px;
}

.invoice-detail-logo {
  float: right;
  margin: 0px;
  max-width: 120px;
  max-height: 120px;
  border-radius: 8px;
}
.invoice-detail-logo::after {
  display: block;
  content: '';
  background: transparent;
}

.invoice-detail-images {
  margin-top: 8px;
  padding-top: 30px;
  border-top: 1px solid #ececec;
}

.invoice-detail-images img {
  max-width: 400px;
  max-height: 200px;
  margin: 0 20px 20px 0;
  filter: grayscale(50%);
  opacity: 0.8;
}

.invoice-detail-images img:hover {
  filter: grayscale(0%);
  opacity: 1;
}

.invoice-notes-input {
  width: 100%;
  resize: none;
}

input.invoice-detail-tax,
input.invoice-detail-payment,
input.invoice-detail-paid-date {
  text-align: right;
  max-width: 120px;
  margin-left: 5px;
}

input.invoice-detail-tax {
  max-width: 60px;
  padding-right: 30px !important;
}

.invoice-detail-accent {
  height: 4px;
}

/* settings */

.invoice-settings {
  margin-top: 45px;
}
.invoice-settings h2 {
  font-weight: 500;
  font-size: 24px;
}
.invoice-settings table {
  width: 100%;
}
.invoice-settings h4 {
  font-size: 18px;
  padding: 30px 0 5px 0;
  margin-bottom: 5px;
}

.signed-date {
  font-weight: 700;
}

.invoice-settings .send-reminder-wrapper,
.invoice-settings .make-invoice-wrapper {
  padding: 10px 16px;
  border-radius: 4px;
  background: white;
  color: #1f2937;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  width: 100%;
  border: 1px solid #dddddd;

  &:hover {
    opacity: 0.8;
  }

  div.send-reminder-link, div.make-invoice-link {
    margin-top: 4px;
    font-weight: 500;
    color: #ff5721;
    display: inline-flex;
    align-items: center;
    &:hover,
    &:focus,
    &:active {
      color: #ff5721;
    }
  }

  div.make-invoice-link {
    color: #2563EB;
    &:hover,
    &:focus,
    &:active {
      color: #2563EB;
    }
  }
}

.invoice-settings .confirm-invoice-delete-button {
  margin-top: 8px;
}

/* select */
.invoice-settings .invoice-discount-type-input,
.invoice-settings .invoice-tax-type-input {
  font-weight: normal !important;
  max-width: 120px !important;
}

/* date */
.invoice-settings .invoice-paid-date-input {
  max-width: 120px;
}

/* checkbox */
.invoice-settings .invoice-tax-inclusive-input,
.invoice-settings .invoice-fully-paid-input {
  margin: 6px 10px;
}

/* short */
.invoice-settings .invoice-tax-label-input,
.invoice-settings .invoice-tax-rate-input,
.invoice-settings .invoice-payment-input {
  max-width: 120px;
}

.col-centered {
  float: none;
  margin: 0 auto;
}

.invoice-detail-header {
  .preview-block {
    margin-top: 0.4em;
  }

  .add-field-buttons {
    display: flex;
    justify-content: flex-end;
    .add-input-field-button {
      margin: 0 0 0 8px;
    }
  }
}

.invoice-detail-terms {
  padding-top: $input-padding-vertical;
  padding-bottom: $input-padding-vertical * 2;
  @media screen and (max-width: 767px) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.invoice-detail-terms table td,
.invoice-address table td {
  padding: 4px 0;
}

.invoice-address-company,
.invoice-address-client {
  padding-bottom: 16px;
  @media screen and (max-width: 767px) {
    padding-bottom: 8px;
  }
}

.invoice-detail-about td label,
.invoice-address table td label {
  padding: 6px 10px 6px 0;
  margin: 0;
}

.invoice-detail-terms table tr td:first-child,
.invoice-address table tr td:first-child {
  width: 75px;
  vertical-align: top;
}

.invoice-detail-about,
.invoice-address table {
  width: 100%;
}

input#invoice-title {
  font-weight: 500;
}

.invoice-detail-body .invoice-notes-input {
  width: 100%;
  resize: none;
}
.invoice-detail-body .invoice-address input {
  width: 100%;
}
.invoice-balance-currency {
  padding-left: 3px;
  font-size: 0.7em;
}

.invoice-notes-header,
.invoice-photos-header {
  font-size: 14px !important;
  font-weight: 500;
  margin: 16px 0;
}

/*icon fixes */
.input-group .input-group-addon {
  border-color: #f3f3f3;
  border-right: 0;
  background-color: #f9f9f9;
  padding: 6px 0 6px 6px;
}

/*
loading/saving labels
*/

.saving-label,
.loading-label {
  padding: 3px 6px;
  position: absolute;
  top: 0px;
  right: 50%;
  margin: 0 -33px 0 0;
  color: #999;
}

/*
suggesion-item
 */

.suggestion-item i {
  position: absolute;
  left: 10px;
  top: 5px;
}

/* */

.invoice-client-address,
.invoice-company-address {
  vertical-align: top;
}

#invoice-client-address2,
#invoice-client-address3,
#invoice-company-address2,
#invoice-company-address3 {
  margin-top: 5px;
}

/*  invoice delete  */
.invoice-delete .invoice-actions {
  opacity: 0.1;
}
.invoice-delete .invoice-detail-body {
  opacity: 0.6;
}

/*  invoice deleted  */
.invoice-deleted .invoice-delete-hidden,
.invoice-deleted .invoice-actions {
  display: none;
}
.invoice-deleted .invoice-detail-body {
  opacity: 0.6;
}

/*  invoice footer buttons */
.invoice-footer {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .btn-cancel {
    margin-right: 10px !important;
  }

  .btn-close {
    border: 1px solid rgb(209, 213, 219);
    background: rgb(229, 231, 235);
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    .confirm-delete-button {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}

.invoice-footer .invoice-title {
  margin-right: 2em;
}

.invoice-detail .invoice-title .invoice-title-snapshot {
  padding: 6px 10px;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  display: block;
  color: #ddd;
}

.terms-item-view {
  display: flex;
  padding: 0.1rem 0;
  span:first-of-type {
    min-width: 30%;
    padding-right: 1rem;
  }
}

.invoice-detail-body > .invoice-add-item > .add-input-field-button {
  margin-left: 48px;
  display: block;
  float: left;
}

.abtest-invoice-margin {
  margin-top: 4rem;

  .invoice-actions .invoice-actions-export .btn-prime {
    background: #ff5722;
    border: 1px solid #ff5722;
  }
  &.row {
    flex-direction: row-reverse;
  }
  .invoice-settings {
    margin-top: 0.5rem;
  }
}
