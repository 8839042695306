.trial-alert {
  padding: 2px 1.25rem;
  border: 1px solid transparent;
  background-color: #333;
  color: #fff;
}
.trial-alert .nav-link-subscription {
  font-size: 12px;
  color: #fff;
  text-decoration: none;

  font-weight: 500;
}
