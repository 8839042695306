.seo-content h2 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 8px;
  margin-top: 32px;
}
.seo-content h3 {
  font-size: 16px;
  font-weight: 500;
  margin: 30px 0 5px 0;
}
.seo-content h4 {
  font-size: 14px;
  line-height: 1.4em;
  margin: 15px 0 5px 0;
  font-weight: 500;
}
.seo-content p {
  font-size: 14px;
  line-height: 1.4em;
  margin: 0 15px 15px 0;
}
.seo-content a {
  text-decoration: underline;
}

.seo-content img {
  max-width: 100%;
  height: auto;
  display: block;
}

.seo-content .seo-content-links {
  padding: 0;
}

.seo-content .list-columns {
  -moz-column-count: 2;
  -moz-column-gap: 60px;
  -webkit-column-count: 2;
  -webkit-column-gap: 60px;
  column-count: 2;
  column-gap: 60px;
  list-style-type: none;
  padding: 0;
}

@media (max-width: 767px) {
  .seo-content .list-columns {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
}

.seo-content .list-columns li {
  margin-bottom: 30px;
}

.seo-content .list-columns li li {
  margin: 0;
}
