.payments-orders-list {
  margin-bottom: 30px;
  table {
    background-color: #fff;
    td,
    th {
      position: relative;
      border: none;
      border-bottom: 1px solid #ececec;
      padding: 1.25rem;
      vertical-align: middle;
      @media screen and (max-width: 767px) {
        padding: 0.625rem;
      }
      i {
        padding: 0 3px;
      }
    }
    td {
      border-top: 1px solid #ececec;
      border-left-style: none;
      border-right-style: none;
    }
    tbody {
      tr:hover {
        background-color: rgba(60, 194, 121, 0.075);
      }
    }
  }
  &-body {
    .btn-link,
    .btn-link:not([href]) {
      color: #3cc279;
      text-decoration: underline;
      cursor: pointer;
    }
    .btn-link:hover,
    .btn-link:not([href]):hover {
      color: #3cc279;
      text-decoration: underline;
    }
    margin-bottom: 30px;
  }
 &-header {
    row-gap: 4px;
    .btn.btn-filter {
      background: rgb(229, 231, 235);
      &.active,
      &.active:hover {
        box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.3), 0 1px 0 rgba(255, 255, 255, 0.5);
      }
    }
    .btn-group .btn {
      word-break: break-all;
    }
  }
  &.loading table {
    opacity: 0.5;
    transition: opacity 0.1s ease-out;
  }
}

@media screen and (max-width: 374px) {
  .payments-orders-list-header {
    display: flex;
    flex-direction: column-reverse;
    align-items: stretch;
    .btn-group {
      display: flex;
      .btn {
        flex: 1 1 auto;
      }
    }
  }
}

.payments-order-status {
  border-radius: 8px;
  padding: 4px 10px;
  @media screen and (min-width: 767px) {
    padding: 4px 20px;
  }
  &.paid {
    background-color: #DCFCE7;
  }
  &.refunded {
    background-color: #FEE2E2;
  }
}

.payments-orders-row-empty td {
  border-bottom: 0 !important;
  color: #999;
  a {
    color: #999;
    margin: 0 5px;
  }
}
