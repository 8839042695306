tr:first-child td {
  border-top: none !important;
}

tr:last-child td {
  border-bottom: none !important;
}

tr > .event-time {
  white-space: nowrap;
}

tr > .share-recipient {
  width: 58%;

  @media screen and (min-width: 992px) {
    width: 68%;
  }

  @media screen and (max-width: 400px) {
    max-width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.invoice-row .status-icon {
  width: 2rem;
  @media screen and (min-width: 640px) {
    margin-right: 0.8rem;
  }
}
