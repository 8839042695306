.btn.btn-payment-setup {
  border: none;
}

.btn.btn-payment-setup.btn-orange {
  background: linear-gradient(#ff5e2d, #ff5722);
}

.btn.btn-payment-setup.btn-orange:hover {
  background: linear-gradient(#ff764c, #ff5722);
}

.btn.btn-payment-setup.btn-grey {
  background: linear-gradient(#555, #444);
}

.btn.btn-payment-setup.btn-grey:hover {
  background: linear-gradient(#666, #444);
}

.btn.btn-payment-setup.btn-white {
  background: white;
}
