.item-suggest .react-autosuggest__suggestions-container--open {
  border: 1px solid #ddd;
  border-top: none;
  top: 33px;
}
.item-suggest .react-autosuggest__input {
  border-radius: 3px !important;
}
.item-suggest .invoice-suggestion-option .invoice-suggestion-name,
.item-suggest .invoice-suggestion-option .invoice-suggestion-meta {
  line-height: 1.5rem !important;
}

.item-suggest .react-autosuggest__input.react-autosuggest__input--open {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.input-group .input-group-btn .btn .fa {
  padding: 0;
}
