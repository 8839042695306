.setting-list-body {
  position: relative;
  min-height: 350px;
  padding: 24px;
  background-color: #fff;
  border: 1px solid #ececec;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
}

.setting-list-footer {
  padding: 15px 0;
}

.setting-list h4:first-child {
  padding-top: 0;
}

.setting-list h4 {
  font-size: 12px;
  border-bottom: 1px solid #ececec;
  text-transform: uppercase;
  color: #333;
  padding: 30px 0 5px 0;
  margin-bottom: 15px;
}

.setting-list h4 .subtitle {
  font-size: 10px;
  display: inline-block;
  margin-left: 3px;
}

.setting-list .photo-drop {
  margin: 0;
}

.setting-list #notes-invoice,
.setting-list #notes-estimate {
  width: 100%;
  resize: none;
  width: 100%;
  resize: none;
  border-radius: 3px;
}

.setting-list textarea {
  padding: 6px 10px;
}
