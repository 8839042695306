.request-review {
  .request-review-info {
    display: flex;
    margin-top: 8px;
    background-color: #e5e7eb;
    padding: 14px 16px;
    font-size: 12px;
    border-radius: 6px;
    justify-content: space-between;
  }

  .link-error {
    color: red;
  }
}
