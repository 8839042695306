.text-with-icon {
  position: relative;
}
.text-with-icon input:not(.form-control) {
  padding-right: 30px;
}
.text-with-icon .fa-check-circle,
.text-with-icon .fa-exclamation-circle {
  font-size: 18px;
  display: inline-block;
  position: absolute;
  top: 7px;
  right: 6px;
  opacity: 0.6;
  width: 24px;
}

.input-with-error .error-message {
  color: red;
  font-size: 10px;
}
