.payment-deposit {
  @media (max-width: 768px) {
    h4 {
      font-size: 12px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      color: #333;
      padding: 30px 0 5px 0;
    }
  }
  .invoice-deposit-rate-input, .invoice-deposit-flat-input, .invoice-deposit-type-input {
    font-weight: normal !important;
    max-width: 120px !important;
  }
}
