/**
 * Using fixed widths for the sig pad container to fix weird cursor offset when drawing signature:
 * https://app.asana.com/0/0/1203779302893897/1204106791953327/f  
 */
.signature-pad-container {
  height: 220px;
  width: 400px;
  margin: 0 auto;
}

@media screen and (max-width: 420px) {
  .signature-pad-container {
    width: 300px;
  }
}

@media screen and (max-width: 320px) {
  .signature-pad-container {
    width: 250px;
  }
}
