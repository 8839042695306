@import './variables';

@mixin smoothFont() {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

@mixin inputCommons {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: $input-padding-vertical $input-padding-horizontal;
  width: 100%;

  &.disabled,
  &:disabled {
    cursor: not-allowed;
  }

  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}

@mixin placeholderDisabled {
  :disabled::-webkit-input-placeholder {
    @content;
  }
  :disabled:-moz-placeholder {
    @content;
  }
  :disabled::-moz-placeholder {
    @content;
  }
  :disabled:-ms-input-placeholder {
    @content;
  }
}
