#app-overlay {
  background: rgba(255, 255, 255, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  cursor: not-allowed;
  z-index: 20;
  pointer-events: unset;
}
